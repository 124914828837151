import { Provider } from '@angular/core';
import { MENU_ITEMS, MENU_MAIN_PATH } from '@konnektu/sidebar-layout';

export const providePharmzillaMenuItems = (tenantCode: string): Provider => ({
  provide: MENU_ITEMS,
  useValue: [
    {
      id: 'segments',
      label: 'menu.entry.segments.title',
      icon: 'knkSegments' as const,
      route: `/${tenantCode}/segments`
    },
    {
      id: 'clients',
      label: 'menu.entry.clients',
      icon: 'knkCustomerCard',
      route: `/${tenantCode}/clients`
    },
    {
      id: 'targetAudiences',
      label: 'menu.entry.targetAudiences',
      icon: 'knkCustomerCard',
      route: `/${tenantCode}/audiences`
    },
    {
      id: 'schemas',
      label: 'menu.entry.campaignSchemas',
      route: `/${tenantCode}/campaign-schemas`,
      icon: 'knkFlow' as const
    },
    {
      id: 'communication',
      label: 'menu.entry.communication.title',
      route: `/${tenantCode}/communications`,
      icon: 'knkCommunication' as const
    },
    {
      id: 'calendar',
      label: 'menu.entry.calendar',
      route: `/${tenantCode}/calendar`,
      icon: 'knkClock' as const
    },
    {
      id: 'advertisingCampaigns',
      label: 'menu.entry.advertisingCampaigns.title',
      icon: 'knkList' as const,
      children: [
        {
          id: 'cabinets',
          label: 'menu.entry.advertisingCampaigns.cabinets',
          route: `/${tenantCode}/segments/exports/cabinets`,
          icon: 'knkList' as const
        },
        {
          id: 'jobs',
          label: 'menu.entry.advertisingCampaigns.media',
          route: `/${tenantCode}/segments/exports`,
          icon: 'knkList' as const
        }
      ]
    },
    {
      id: 'yandex-gpt',
      label: 'menu.entry.gpt.title',
      icon: 'knkAi' as const,
      route: `/${tenantCode}/yandex-gpt`
    }
  ]
});

export const providePharmzillaMainPath = (tenantCode: string): Provider => ({
  provide: MENU_MAIN_PATH,
  useValue: `${tenantCode}/clients`
});
