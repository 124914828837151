import {
  HttpClient,
  provideHttpClient,
  withInterceptors
} from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withPreloading } from '@angular/router';
import { authFuncInterceptor } from '@konnektu/auth';
import {
  ModuleLoadingStrategyService,
  createContextLogger
} from '@konnektu/helpers';
import { METASTORE_API_URL, MetastoreModule } from '@konnektu/metastore';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SIMPLE_FILTER_TEMPLATES } from '@konnektu/segments-view';
import { provideKonnektuTaiga } from '@konnektu/taiga';
import { API_URL, INSTANCE_CODE } from '@konnektu/tokens';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { merge } from 'lodash-es';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import {
  MESSAGE_FORMAT_CONFIG,
  TranslateMessageFormatCompiler
} from 'ngx-translate-messageformat-compiler';
import { concatMap, map } from 'rxjs';
import { AppComponent } from './app/app.component';
import { createTenantRoutes } from './app/application-routes';
import {
  providePharmzillaMainPath,
  providePharmzillaMenuItems
} from './app/menu-items';
import { PHARMA_SIMPLE_FILTER_TEMPLATES } from './app/segments/simple-filter-templates';

function initStaticTranslations(translateService: TranslateService) {
  return () =>
    new Promise<void>((resolve) => {
      const staticTranslationsLogger = createContextLogger(
        'StaticTranslationInitializer'
      );
      translateService.langs = ['ru', 'en'];
      translateService.onLangChange.subscribe((event) => {
        localStorage.setItem('lang', event.lang);
      });
      const langToSet = localStorage.getItem('lang') ?? 'ru';
      if (langToSet !== translateService.currentLang) {
        translateService.use(langToSet);
      }
      staticTranslationsLogger.debug('Initialized static translations');
      resolve();
    });
}

class ApiTranslateLoader implements TranslateLoader {
  private readonly defaultLoader: TranslateHttpLoader;

  constructor(
    private readonly http: HttpClient,
    private readonly instanceCode: string
  ) {
    this.defaultLoader = new TranslateHttpLoader(
      http,
      './assets/i18n/',
      '.json'
    );
  }

  getTranslation(lang: string) {
    return this.http
      .get<object>(
        `${this.instanceCode}/api/uzilla/v1/translations?key=main&lang=${lang}`
      )
      .pipe(
        concatMap((resp) =>
          this.defaultLoader
            .getTranslation(lang)
            .pipe(map((staticTranslations) => merge(staticTranslations, resp)))
        )
      );
  }
}

export function createTranslateLoader(http: HttpClient, instanceCode: string) {
  return new ApiTranslateLoader(http, instanceCode);
}

export function provideMonacoEditor() {
  return importProvidersFrom(MonacoEditorModule.forRoot());
}

export async function bootstrapTenant(tenantCode: string) {
  await bootstrapApplication(AppComponent, {
    providers: [
      provideAnimations(),
      provideHttpClient(withInterceptors([authFuncInterceptor])),
      provideRouter(
        createTenantRoutes(tenantCode),
        withPreloading(ModuleLoadingStrategyService)
      ),
      provideKonnektuTaiga(),
      importProvidersFrom(
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient, INSTANCE_CODE]
          },
          compiler: {
            provide: TranslateCompiler,
            useClass: TranslateMessageFormatCompiler
          },
          defaultLanguage: 'ru'
        }),
        MatTooltipModule,
        MetastoreModule.forRoot(),
        StoreModule.forRoot(
          {
            router: routerReducer
          },
          {
            runtimeChecks: {
              strictActionImmutability: false,
              strictActionSerializability: false,
              strictStateSerializability: false
            }
          }
        ),
        EffectsModule.forRoot(),
        StoreRouterConnectingModule.forRoot()
      ),
      providePharmzillaMenuItems(tenantCode),
      providePharmzillaMainPath(tenantCode),
      provideMonacoEditor(),
      {
        provide: MESSAGE_FORMAT_CONFIG,
        useValue: { locales: ['en', 'ru'] }
      },
      {
        provide: API_URL,
        useValue: `${tenantCode}/ui-api`
      },
      {
        provide: METASTORE_API_URL,
        useFactory: (apiUrl: string) => `${apiUrl}/metastore`,
        deps: [API_URL]
      },
      {
        provide: INSTANCE_CODE,
        useValue: tenantCode
      },
      {
        provide: APP_INITIALIZER,
        multi: true,
        useFactory: initStaticTranslations,
        deps: [TranslateService]
      },
      {
        provide: SIMPLE_FILTER_TEMPLATES,
        useValue: PHARMA_SIMPLE_FILTER_TEMPLATES
      }
    ]
  });
}
