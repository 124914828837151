import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { AcsGuard } from '@konnektu/acs-helpers';
import { tenantAuthGuard } from '@konnektu/auth';
import { CommunicationApiService } from '@konnektu/communication/api';
import { LayoutComponent } from '@konnektu/sidebar-layout';
import { catchError, throwError } from 'rxjs';
import { PharmzillaCommunicationService } from './communication/pharmzilla-communication.service';

export function createTenantRoutes(tenantCode: string): Routes {
  return [
    {
      path: tenantCode,
      children: [
        {
          path: 'cabinet-response',
          loadComponent: () =>
            import('@konnektu/segments/export').then(
              (m) => m.OauthRedirectPageComponent
            )
        },
        {
          path: 'login',
          loadComponent: () =>
            import('@konnektu/login-page').then((l) => l.LoginPageComponent)
        },
        {
          path: '',
          canActivate: [tenantAuthGuard(tenantCode), AcsGuard],
          canActivateChild: [tenantAuthGuard(tenantCode), AcsGuard],
          children: [
            {
              path: '',
              redirectTo: 'clients',
              pathMatch: 'full'
            },
            {
              path: '',
              component: LayoutComponent,
              data: {
                breadcrumb: 'breadcrumbs.main'
              },
              children: [
                {
                  path: 'segments/exports',
                  data: {
                    breadcrumb: {
                      label: 'menu.entry.advertisingCampaigns.title',
                      link: null
                    }
                  },
                  children: [
                    {
                      path: '',
                      loadComponent: () =>
                        import('@konnektu/segments/export').then(
                          (c) => c.ExportJobSectionComponent
                        ),
                      data: {
                        breadcrumb: 'menu.entry.advertisingCampaigns.media'
                      }
                    },
                    {
                      path: 'cabinets',
                      loadComponent: () =>
                        import('@konnektu/segments/export').then(
                          (c) => c.CabinetSectionComponent
                        ),
                      data: {
                        breadcrumb: 'menu.entry.advertisingCampaigns.cabinets'
                      }
                    }
                  ]
                },
                {
                  path: 'yandex-gpt',
                  data: {
                    breadcrumb: 'menu.entry.gpt.title'
                  },
                  loadComponent: () =>
                    import('@konnektu/yandex-gpt').then(
                      (c) => c.YandexGptChatComponent
                    )
                },
                {
                  path: 'segments',
                  loadChildren: () =>
                    import('@konnektu/segments-view').then(
                      (m) => m.SegmentsModule
                    )
                },
                {
                  path: 'calendar',
                  loadChildren: () =>
                    import('@konnektu/calendar').then((m) => m.CalendarModule)
                },
                {
                  path: 'communications',
                  children: [
                    {
                      path: '',
                      data: {
                        breadcrumb: 'menu.entry.communication.title'
                      },
                      children: [
                        {
                          path: '',
                          loadComponent: () =>
                            import('@konnektu/communication/section').then(
                              (c) => c.CommunicationSectionComponent
                            ),
                          data: { breadcrumb: null }
                        },
                        {
                          path: ':communicationId',
                          data: {
                            breadcrumb: (data: {
                              communication: { name: string };
                            }) => data.communication.name
                          },
                          resolve: {
                            communication: (route: ActivatedRouteSnapshot) => {
                              const communicationService = inject(
                                CommunicationApiService
                              );
                              return communicationService.getTemplate(
                                parseInt(route.params['communicationId'], 10)
                              );
                            }
                          },
                          loadComponent: () =>
                            import(
                              '../app/communication/pharmzilla-communication.component'
                            ).then((c) => c.PharmzillaCommunicationComponent)
                        },
                        {
                          path: ':communicationId/settings',
                          resolve: {
                            communication: (route: ActivatedRouteSnapshot) => {
                              const communicationService = inject(
                                CommunicationApiService
                              );
                              return communicationService.getTemplate(
                                parseInt(route.params['communicationId'], 10)
                              );
                            }
                          },
                          data: {
                            breadcrumb: (data: {
                              communication: { name: string; id: number };
                            }) => ({
                              label: data.communication.name,
                              link: `communications/${data.communication.id}`
                            })
                          },
                          children: [
                            {
                              path: '',
                              loadComponent: () =>
                                import(
                                  './communication/upharma-settings/upharma-settings.component'
                                ).then((c) => c.UpharmaSettingsComponent),
                              resolve: {
                                communicationSettings: (
                                  route: ActivatedRouteSnapshot
                                ) => {
                                  const pharmzillaCommunicationSettingService =
                                    inject(PharmzillaCommunicationService);
                                  const communicationId = parseInt(
                                    route.params['communicationId'],
                                    10
                                  );
                                  return pharmzillaCommunicationSettingService
                                    .getSettings(communicationId)
                                    .pipe(
                                      catchError((error) => {
                                        if (
                                          error instanceof HttpErrorResponse &&
                                          error.status === 404
                                        ) {
                                          return pharmzillaCommunicationSettingService.saveDefaultSettings(
                                            communicationId
                                          );
                                        }
                                        return throwError(() => error);
                                      })
                                    );
                                }
                              },
                              data: {
                                breadcrumb: 'Settings'
                              }
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'campaign-schemas',
                  loadChildren: () =>
                    import('@konnektu/campaign-schema').then(
                      (m) => m.CampaignSchemaModule
                    )
                },
                {
                  path: 'clients',
                  loadChildren: () =>
                    import('./clients/client.routes').then(
                      (r) => r.CLIENT_ROUTES
                    )
                },
                {
                  path: 'audiences',
                  loadChildren: () =>
                    import('./target-audiences/target-audience.routes').then(
                      (r) => r.TARGET_AUDIENCE_ROUTES
                    )
                },
                {
                  path: 'analytics',
                  loadChildren: () =>
                    import('@konnektu/analytics-view').then(
                      (a) => a.AnalyticsViewRoutingModule
                    ),
                  data: {
                    preload: true
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '**',
      loadComponent: () =>
        import('@konnektu/status-page').then((c) => c.NotFoundPageComponent)
    }
  ];
}
